import React from 'react';
import { graphql } from 'gatsby';
import { fbq } from '@hutsoninc/gatsby-plugin-facebook-pixel';
import { Hero, Seo } from '../shared/ui';

fbq('track');
const RootIndex = (props) => {
  const data = props.data.allContentfulBrandsList.nodes[0].brands;

  const projects = [...data].filter((project) => project.featured);

  return (
    <>
      <Seo title="Home - Bolt Tv" />
      <Hero projects={projects} />
    </>
  );
};

// export const query = graphql`
//   query QueryBrandsList {
//     allContentfulBrandsList {
//       nodes {
//         brands {
//           slug
//           featured
//         }
//       }
//     }
//   }
// `;
export const query = graphql`
  query QueryBrandsList {
    allContentfulBrandsList {
      nodes {
        brands {
          title
          slug
          featured
          category
          subtitle
          featuredTitle
          client
          videoPromo {
            url
          }
          featuredVideo {
            url
          }
          featuredVideo2 {
            url
          }
          logo {
            url
          }
          director
          mainImage {
            url
            title
          }
          id
          homeOrder
          featuredVideoLink
          featuredVideo2link
        }
      }
    }
  }
`;

export default RootIndex;
